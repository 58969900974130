
import Vue from 'vue'
import Component from 'vue-class-component'
// import Axios from 'axios'
import { InjectReactive, Prop } from 'vue-property-decorator'

import { stringUtils } from '@/utils/string'

@Component({
  name: 'CompanyCard'
})

export default class CompanyCard extends Vue {
  @Prop(Object) leasingCompany!: any
  @InjectReactive() selectedCompanies!: any

  get maxCountMounths() {
    return stringUtils.pluralForm(this.leasingCompany.leasTerm.max, ['месяц', 'месяца', 'месяцев'])
  }

  get cardState() {
    return this.selectedCompanies.indexOf(this.leasingCompany.id) !== -1
      ? 'company-card_selected'
      : this.leasingCompany.isFavorite
        ? 'company-card_favorite'
        : ' card-company_simple'
  }

  selectCompany(id) {
    const companyIndex = this.selectedCompanies.indexOf(id)
    companyIndex === -1
      ? this.selectedCompanies.push(id)
      : this.selectedCompanies.splice(companyIndex, 1)
  }
}
