
import Vue from 'vue'
import Component from 'vue-class-component'
import moment from 'moment'
// import Axios from 'axios'

import { ProvideReactive } from 'vue-property-decorator'

import LeasRequestClient from '@/views/LeasRequest/LeasRequestClient.vue'
import LeasRequestCompany from '@/views/LeasRequest/LeasRequestCompany.vue'
import LeasRequestOffer from '@/views/LeasRequest/LeasRequestOffer.vue'
import { CarBrand, CarModel, LeasOffer, ResponsiblePerson } from '@/typings/LeasRequestTypings'

@Component({
  name: 'LeasRequest',
  components: {
    LeasRequestClient,
    LeasRequestCompany,
    LeasRequestOffer
  }
})

export default class LeasRequest extends Vue {
  // @ts-ignore
  @ProvideReactive() carBrands = this.carBrands
  // @ts-ignore
  @ProvideReactive() carModels = this.carModels
  // @ts-ignore
  @ProvideReactive() responsiblePersons = this.responsiblePersons

  date: any
  currentDate: string

  client = {
    name: '',
    actualAddress: '',
    inn: '',
    representative: {
      fullname: '',
      phone: '',
      email: '',
      post: ''
    }
  }

  offers: LeasOffer[] = [
    {
      carDetails: {
        brandId: null,
        modelId: null,
        equipment: '',
        price: ''
      },
      leasingDetails: {
        prepaymentPercent: 0,
        prepaymentAmount: 0,
        leasTearm: 0,
        carCount: 0,
        balanceHolderId: ResponsiblePerson.Client,
        registrationPersonId: ResponsiblePerson.Client,
        transportTaxPersonId: ResponsiblePerson.Client
      },
      selectedLeasingCompanies: []
    }
  ]

  // @ts-ignore
  carBrands: CarBrand[] = [
    { id: 1, name: 'BMW' },
    { id: 2, name: 'Audi' },
    { id: 3, name: 'Lada' },
    { id: 4, name: 'Chevrolet' }
  ]

  // @ts-ignore
  carModels: CarModel[] = [
    { id: 1, name: 'Vesta' },
    { id: 2, name: 'X-Ray' },
    { id: 3, name: 'Niva' },
    { id: 4, name: 'Priora' }
  ]

  // @ts-ignore
  responsiblePersons = [
    { id: 1, name: 'Клиент' },
    { id: 2, name: 'Компания' }
  ]

  constructor() {
    super()
    this.date = moment()
    this.currentDate = this.date.format('L')
  }

  getCommercialProposal() {
    // @ts-ignore
    this.showCongratsSystemNotification('Ваш запрос успешно отправлен')
  }
}
