
import Vue from 'vue'
import Component from 'vue-class-component'
import moment, { Moment } from 'moment'

@Component({
  name: 'LeasRequestClient'
})

export default class LeasRequestClient extends Vue {
  date: Moment
  client = {
    typeClientId: 1
  }

  clientTypes = [
    { id: 1, name: 'Юридическое лицо' },
    { id: 2, name: 'Физическое лицо' }
  ]

  isDefferedRequest = false
  showDatePicker = false
  applicationDate: string|null = null
  showTimePicker = false
  applicationTime: string|null = null

  constructor() {
    super()
    this.date = moment()
  }

  get formatingDate() {
    return this.applicationDate ? moment(this.applicationDate).format('L') : null
  }
}
