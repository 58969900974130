
import Vue from 'vue'
import Component from 'vue-class-component'
// import Axios from 'axios'
import { InjectReactive, Prop } from 'vue-property-decorator'
import { LeasingDetails } from '@/typings/LeasRequestTypings'

import CsInputWithSlider from '@/views/components/CsInputWithSlider.vue'
import CompanyCard from '@/views/components/CompanyCard.vue'

@Component({
  name: 'LeasingConstructor',
  components: {
    CsInputWithSlider,
    CompanyCard
  }
})

export default class LeasingConstructor extends Vue {
  @Prop(Object) leasingDetails!: LeasingDetails
  @InjectReactive() responsiblePersons!: any
  @InjectReactive() leasingCompanies!: any
}
