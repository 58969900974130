export interface LeasRequest {
  id?: number;
  client?: LeasClient;
  offers?: LeasOffer[] | [];
}

export interface LeasClient {
  id?: number;
  name?: string;
  actualAddress: string;
  inn: string;
  representative: LeasClientRepresentative;
}

export interface LeasClientRepresentative {
  id?: number;
  fullname: string;
  phone: string;
  email: string;
  post: string;
}

export interface LeasOffer {
  id?: number;
  carDetails: CarDetails;
  leasingDetails: LeasingDetails;
  selectedLeasingCompanies: LeasingCompany[] | [];
}

export interface CarDetails {
  brandId?: number|null;
  modelId?: number|null;
  equipment?: string;
  price?: string;
}

export interface LeasingDetails {
  prepaymentPercent: number;
  prepaymentAmount: number;
  leasTearm: number;
  carCount: number;
  balanceHolderId: ResponsiblePerson;
  registrationPersonId: ResponsiblePerson;
  transportTaxPersonId: ResponsiblePerson;
}

export enum ResponsiblePerson {
  Client= 1,
  Company
}

export interface LeasingCompany {
  id: number;
  name: string;
  logo: string;
  minRequirements: LCminRequirements;
}

export interface LCminRequirements {
  opfType?: OPFType;
  businessDurationMonths?: number;
  hasSurety?: boolean|null;
  suretyBusinessDuration?: number;
  registrationRegionId?: RegistrationRegion;
  leasingObjectType?: LeasingObjectType;
  leasingAmount: LeasingAmount;
  leasingPrepaymentPercent: LeasingPrepaymentPercent;
}

export interface OPFType {
  id: number;
  name: string;
}

export interface RegistrationRegion {
  id: number;
  name: string;
}

export interface LeasingObjectType {
  id: number;
  name: string;
}

export interface LeasingAmount {
  min: number;
  max: number;
}

export interface LeasingPrepaymentPercent {
  min: number;
  max: number;
}

export interface CarBrand {
  id: number;
  name: string;
}

export interface CarModel {
  id: number;
  name: string;
}
