
import Vue from 'vue'
import Component from 'vue-class-component'
// import Axios from 'axios'

import CarConfigurator from '@/views/components/CarConfigurator.vue'
import LeasingConstructor from '@/views/components/LeasingConstructor.vue'

import { Prop, ProvideReactive } from 'vue-property-decorator'
import { LeasOffer } from '@/typings/LeasRequestTypings'

@Component({
  name: 'LeasRequestTransport',
  components: {
    CarConfigurator,
    LeasingConstructor
  }
})

export default class LeasRequestTransport extends Vue {
  @Prop(Object) offer!: LeasOffer
  // @ts-ignore
  @ProvideReactive() leasingCompanies = this.leasingCompanies
  // @ts-ignore
  @ProvideReactive() selectedCompanies = this.selectedCompanies

  showDetails = false

  // @ts-ignore
  selectedCompanies = []

  // @ts-ignore
  leasingCompanies = [
    {
      id: 1,
      minAmount: 10000000,
      minPrepaymentPercent: 25,
      leasTerm: {
        min: 12,
        max: 36
      },
      minPayment: 120000,
      isFavorite: true
    },
    {
      id: 2,
      minAmount: 12000000,
      minPrepaymentPercent: 35,
      leasTerm: {
        min: 10,
        max: 40
      },
      minPayment: 165000,
      isFavorite: true
    },
    {
      id: 3,
      minAmount: 20000000,
      minPrepaymentPercent: 15,
      leasTerm: {
        min: 24,
        max: 50
      },
      minPayment: 180000,
      isFavorite: false
    },
    {
      id: 4,
      minAmount: 18500000,
      minPrepaymentPercent: 10,
      leasTerm: {
        min: 12,
        max: 48
      },
      minPayment: 90000,
      isFavorite: false
    }
  ]
}
