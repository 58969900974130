
import Vue from 'vue'
import Component from 'vue-class-component'
// import Axios from 'axios'
import { Prop, PropSync } from 'vue-property-decorator'

@Component({
  name: 'CsInputWithSlider'
})

export default class CsInputWithSlider extends Vue {
  @Prop(Number) readonly max!: number
  @Prop(String) readonly unit!: string
  @Prop(String) readonly label!: string
  @Prop(String) readonly placeholder!: string
  @Prop({ default: 0.1 }) readonly step!: number
  @PropSync('value', { type: Number }) valueSync!: number
}
