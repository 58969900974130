
import Vue from 'vue'
import Component from 'vue-class-component'
import { InjectReactive, Prop } from 'vue-property-decorator'
import { CarBrand, CarDetails, CarModel } from '@/typings/LeasRequestTypings'

@Component({
  name: 'CarConfigurator'
})

export default class CarConfigurator extends Vue {
  @InjectReactive() carBrands!: CarBrand[]
  @InjectReactive() carModels!: CarModel[]
  @Prop(Object) carDetails!: CarDetails
}
